<template>
  <div class="mt-md-15" style="margin-bottom: 200px">
    <Card v-if="!noService" v-for="service in services" :key="service.id">
      <template>
        <v-card-title> Servicio publicado el {{ service.date }} </v-card-title>
        <v-card-subtitle> {{ service.client }} </v-card-subtitle>
        <v-card-text>
          <iframe :src="service.pdf" width="100%" height="350px" />
        </v-card-text>
      </template>
    </Card>
    <Card v-if="noService">
      <v-card-title v-if="!loading">
        No se encontraron resultados para la patente: "{{ patent }}"
      </v-card-title>
      <v-row class="ma-0">
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
          v-if="loading"
          class="mx-auto my-10"
        ></v-progress-circular>
      </v-row>
    </Card>
  </div>
</template>

<script>
// import pdf from "vue-pdf";
import Card from "@/components/card.vue";
export default {
  name: "Client",
  data: () => ({}),
  computed: {
    services() {
      return this.$store.state.clientService;
    },
    noService() {
      return (
        this.$store.state.clientService == null ||
        this.$store.state.clientService.length <= 0
      );
    },
    patent() {
      return this.$route.params.patent;
    },
    loading() {
      return this.$store.state.searching;
    },
  },
  components: {
    Card,
  },
  created() {
    if (this.patent != null)
      this.$store.dispatch("searchByPatent", this.patent);
  },
  methods: {},
};
</script>

<style scoped>
iframe {
  border: 0;
}
</style>